import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect, useState } from "react";

const CarouselCovers = () => {

    const [marginCarousel, setMarginCarousel] = useState<number>(50);

    useEffect(() => {
        changeSize();

        const resizeListener = () => {
            changeSize();
        };

        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        }
    }, []);


    const changeSize = () => {
        // change width from the state object
        // If on pad
        if (window.innerWidth < 1200) {
            setMarginCarousel(30);
        } else {
            resetSizes()
        }
        // If on smartphone
        if (window.innerWidth < 570) {
            setMarginCarousel(10);
        } else {
            resetSizes()
        }
    }

    const resetSizes = () => {
        // Reset
        setMarginCarousel(50);
    }

    return (
        <OwlCarousel className="owl-theme"
            loop margin={marginCarousel}
            center
            autoplay
            responsiveClass
            dots
            autoplayTimeout={4000}
            smartSpeed={800}
            nav>
            <>
                <div className="item">
                    <img src="img/components/home/covers/poc1.jpg" />
                </div>
                <div className="item">
                    <img src="img/components/home/covers/poc2.jpg" />
                </div>
                <div className="item">
                    <img src="img/components/home/covers/poc3.jpg" />
                </div>
                <div className="item">
                    <img src="img/components/home/covers/poc4.jpg" />
                </div>
                <div className="item">
                    <img src="img/components/home/covers/poc5.jpg" />
                </div>
            </>

        </OwlCarousel>
    );
};

export default CarouselCovers;