import React, { useEffect, useState } from 'react';

const SocialNetworks = () => {

    const [socialNetworksCss, setSocialNetworksCss] = useState('socialNetworks');

    useEffect(() => {
        changeSize();

        const resizeListener = () => {
            changeSize();
        };

        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        }
    }, []);


    const changeSize = () => {
        // change width from the state object
        // If screen rotation
        if (window.innerHeight < window.innerWidth) {
            // If on pad
            if (window.innerWidth < 1200) {
                setSocialNetworksCss('socialNetworks-horizontal');
            } else {
                resetSizes();
            }
            // If on smartphone
            if (window.innerHeight < 570) {
                setSocialNetworksCss('socialNetworks-horizontal-smartphone');

            } else {
                resetSizes();
            }

        } else {
            resetSizes();
        }
    }

    const resetSizes = () => {
        // Reset
        setSocialNetworksCss('socialNetworks');
    }

    return (
        <div className={socialNetworksCss}>
            <ul>
                <li>
                    <a href='https://open.spotify.com/artist/3LZegs6tK4WeVei1K0WaNU?si=OCEjpuQ3TZa51dpQAq37PQ' target={'_blank'}><img src="./img/components/home/youtube_3.png" alt="Youtube-dovicente" /></a>
                </li>
                <li>
                    <a href='https://open.spotify.com/artist/3LZegs6tK4WeVei1K0WaNU?si=OCEjpuQ3TZa51dpQAq37PQ' target={'_blank'}><img src="./img/components/home/spotify-logo.png" alt="Spotify-dovicente" /></a>
                </li>
                <li>
                    <a href='https://www.instagram.com/dovicente.music/' target={'_blank'}><img src="./img/components/home/instagram.png" alt="Instagram-dovicente" /></a>
                </li>
                <li>
                    <a href='https://www.deezer.com/fr/artist/58064232' target={'_blank'}><img src="./img/components/home/soundcloud-logo.png" alt="Soundcloud-dovicente" /></a>
                </li>
                <li>
                    <a href='https://www.deezer.com/fr/artist/58064232' target={'_blank'}><img src="./img/components/home/deezer.png" alt="Deezer-dovicente" /></a>
                </li>
            </ul>
        </div>
    );
};

export default SocialNetworks;