import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CarouselCovers from '../components/home/CarouselCovers';
import CarouselNFTs from '../components/home/CarouselNFTs';
import SocialNetworks from '../components/home/SocialNetworks';

const Home = () => {
    const { t, i18n } = useTranslation(['pages\\homeTr']);

    const [titleCss, setTitleCss] = useState('title');
    const [bottomBlock, setBottomBlock] = useState('bottom-block');
    const [navBtn, setNavBtn] = useState('nav-btn');
    const [aTitlesCss, setATitlesCss] = useState('a-active');
    const [aNFTsCss, setANFTsCss] = useState('');
    const [nbCarousel, setNbCarousel] = useState(0);


    useEffect(() => {
        changeSize();
        const resizeListener = () => {
            changeSize();
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        }
    }, []);


    const changeSize = () => {
        // change width from the state object
        // If screen rotation
        if (window.innerHeight < window.innerWidth) {
            // If on pad
            if (window.innerWidth < 1200 || window.innerHeight < 570) {
                if (window.innerWidth < 1200) {
                    setTitleCss('title-horizontal');
                    setBottomBlock('bottom-block');
                    setNavBtn('nav-btn');
                }
                if (window.innerHeight < 570) {
                    setTitleCss('title-horizontal-smartphone');
                    setBottomBlock('bottom-block-smartphone');
                    setNavBtn('nav-btn-horizontal-smartphone');
                }
                // Reset
            } else {
                resetSizes();
            }
            // Reset
        }else {
            resetSizes();
        }
    }

    const resetSizes = () => {
        // Reset
        setTitleCss('title');
        setBottomBlock('bottom-block');
        setNavBtn('nav-btn');
    }

    const changeView = (nb: number) => {
        if (nb === 0) {
            setATitlesCss('a-active');
            setANFTsCss('');
            setNbCarousel(0);
        } else {
            setATitlesCss('');
            setANFTsCss('a-active');
            setNbCarousel(1);
        }
    }

    return (
        <div className='home'>
            <div className={titleCss}>
                <span>
                    <img src="./img/pages/home/doVicente-title-web.png" alt="Dovicente" />
                </span>
            </div>
            <SocialNetworks />
            <div className={bottomBlock}>
                <div className={navBtn}>
                    <a className={aTitlesCss} onClick={() => changeView(0)}> <img src="img/pages/home/Titres.png" alt="Titres" /> </a>
                    <a className={aNFTsCss} onClick={() => changeView(1)}> <img src="img/pages/home/NFTs.png" alt="Titres" /> </a>
                </div>
                {nbCarousel === 0 &&
                    <CarouselCovers />
                }
                {nbCarousel === 1 &&
                    <CarouselNFTs />
                }
            </div>
        </div>
    );
};

export default Home;