import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import './i18n';
import { Suspense, useEffect, useState } from 'react';
import Translate from './components/tools/Translate';

// loading component for suspense fallback
const Loader = () => (
  <div>
    <div>Loading</div>
  </div>
);

function App() {

  const [backgroundCss, setBackgroundCss] = useState('background');
  const [dovicenteCss, setDovicenteCss] = useState('dovicente');
  const [dovicenteImg, setDovicenteImg] = useState('./img/app/dovi.png');



  useEffect(() => {
    changeSize();
    const resizeListener = () => {
      changeSize();
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, []);


  const changeSize = () => {
    // change width from the state object
    // If screen rotation
    if (window.innerHeight < window.innerWidth) {
      // If on smartphone
      if (window.innerHeight < 570) {
        setBackgroundCss('horizontal-background-smartphone')
        setDovicenteCss('horizontal-dovicente-smartphone')
      } else {
        // If on pad
        if (window.innerWidth < 1200) {
          setBackgroundCss('horizontal-background');
          setDovicenteCss('horizontal-dovicente')
        } else {
          resetSizes();
        }
      }
    } else {
      // If on smartphone
      if (window.innerWidth < 570) {
        setDovicenteImg('./img/app/dovi_smartphone.png')
      } else {
        resetSizes();
      }
    }
  }

  const resetSizes = () => {
    // Reset
    setBackgroundCss('background');
    setDovicenteCss('dovicente')
    setDovicenteImg('./img/app/dovi.png')
  }


  return (
    <Suspense fallback={<Loader />}>
      <div className={backgroundCss}>
        <div className={dovicenteCss}><img src={dovicenteImg} alt="Dovicente" /></div>
        <BrowserRouter basename="/">
          <Routes>
            <Route path="*" element={<Home />} />
          </Routes>

        </BrowserRouter>
      </div>
    </Suspense>
  );
}

export default App;
